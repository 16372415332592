import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITicket {

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4005/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4005/";

    }

    return result + nome_metodo;

  }


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getDLLByAzienda(id_azienda) {

    let formData = new FormData();

    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("getDLLByAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  // async getInitCreate(user)
  // {

  //   let formData = new FormData();

  //   formData.append("username",  user);

  //   return axios.post(this.getUrlDomain("initCreateTicketApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  // async getInitCreate(user) {

  //   let formData = new FormData();

  //   formData.append("username", user);

  //   return axios.post(this.getUrlDomain("goInitInsertTicketHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async getInitCreate(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomainGo("initInsertTicket"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getSottoCategorie(id_categoria) {

    let formData = new FormData();

    formData.append("id_categoria", id_categoria);

    return axios.post(this.getUrlDomain("getSottoCategorieApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insTicketApi(ticket, user, file, type_tck) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("image", file);
    formData.append("type_tck", type_tck);

    for (var key in ticket) {
      formData.append(key, ticket[key]);
    }

    return axios.post(this.getUrlDomain("insTicketApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async insTicketApi(ticket, user, file, type_tck) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("image", file);
  //   formData.append("type_tck", type_tck);

  //   for (var key in ticket) {
  //     formData.append(key, ticket[key]);
  //   }

  //   return axios.post(this.getUrlDomain("goInsertTicketHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }



  async getTicketApi(id, user) {

    return axios.get(this.getUrlDomain("initEditTicketApi") + "/" + id + "?username=" + user, { 'Content-Type': 'multipart/form-data' });

  }


  async updTicketApi(ticket, id, file, att_remove) {

    let formData = new FormData();

    formData.append("image", file);
    formData.append("att_remove", att_remove);

    for (var key in ticket) {
      formData.append(key, ticket[key]);
    }

    return axios.post(this.getUrlDomain("updTicketApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async delTicketApi(id) {

  //   return axios.get(this.getUrlDomain("delTicketApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  // }

  // async delTicketApi(id) {

  //   let formData = new FormData();

  //   formData.append("id", id);

  //   return axios.post(this.getUrlDomain("goDeleteTicketHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async delTicketApi(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("deleteTicket"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getCategorieByUserAndCompanyApi(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getCategorieByUserAndCompanyApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getSottoCategorieByCategoryApi(user, id_category) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("category_id", id_category);

    return axios.post(this.getUrlDomain("getSottoCategorieByCategoryApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validateUserAssignApi(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomain("validateUserAssignApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async userAssignSaveApi(ticket, id) {

    let formData = new FormData();


    for (var key in ticket) {
      formData.append(key, ticket[key]);
    }

    return axios.post(this.getUrlDomain("userAssignSaveApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateStatusApi(id, status_change, user, v_feedback) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("class", status_change);
    formData.append("username", user);
    formData.append("feedback", v_feedback);

    return axios.post(this.getUrlDomain("updateStatusApiHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getCompanyAutoAssignApi(companyid) {

    let formData = new FormData();

    formData.append("companyid", companyid);

    return axios.post(this.getUrlDomain("getCompanyAutoAssignApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async saveCompanyAutoAssignApi(autoassegnamento) {

    let formData = new FormData();

    for (var key in autoassegnamento) {
      formData.append(key, autoassegnamento[key]);
    }

    return axios.post(this.getUrlDomain("saveCompanyAutoAssignApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async initCreateTicketFromProd(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("goInitInsertTicketHdToolFromProd"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APITicket({
  url: "http://emtool.local/public/api/smartbook/"
})
