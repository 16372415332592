import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICategorie {

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4005/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4005/";

    }

    return result + nome_metodo;

  }



  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  // async insCategoriaApi(categoria) {

  //   let formData = new FormData();

  //   for (var key in categoria) {
  //     formData.append(key, categoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("insCategoriaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }


  // async insCategoriaApi(categoria)
  // {

  //   let formData = new FormData();

  //   for ( var key in categoria ) {
  //      formData.append(key, categoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("goInsertCategoriaHdTool") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }

  async insCategoriaApi(categoria) {

    let formData = new FormData();

    for (var key in categoria) {
      formData.append(key, categoria[key]);
    }

    return axios.post(this.getUrlDomainGo("insertCategoria"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async getCategoriaApi(id) {

  //   return axios.get(this.getUrlDomain("getCategoriaApi") + "/" + id + "?api=1", { 'Content-Type': 'multipart/form-data' });

  // }

  // async getCategoriaApi(id)
  // {

  //   let formData = new FormData();

  //   formData.append("id", id);

  //   return axios.post(this.getUrlDomain("goEditCategoriaHdTool"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  async getCategoriaApi(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("editCategoria"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  // async updCategoriaApi(categoria, id)
  // {


  //   let formData = new FormData();

  //   for ( var key in categoria ) {
  //      formData.append(key, categoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("updCategoriaApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  // async updCategoriaApi(categoria, v_id) {


  //   let formData = new FormData();

  //   formData.append("v_id", v_id);

  //   for (var key in categoria) {
  //     formData.append(key, categoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("goUpdateCategoriaHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async updCategoriaApi(categoria, v_id) {


    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in categoria) {
      formData.append(key, categoria[key]);
    }

    return axios.post(this.getUrlDomainGo("updateCategoria"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  // async delCategoriaApi(id) {

  //   return axios.get(this.getUrlDomain("delCategoriaApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  // }


  // async delCategoriaApi(id)
  // {

  //   let formData = new FormData();

  //   formData.append("id", id);

  //   return axios.post(this.getUrlDomain("goDeleteCategoriaHdTool"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  async delCategoriaApi(id)
  {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("deleteCategoria"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  // async initInsertAssoc(id) {

  //   let formData = new FormData();

  //   formData.append("id", id);

  //   return axios.post(this.getUrlDomain("goInitInsertAssocCategoriaGruppoHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async initInsertAssoc(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("initInsertAssocCategoriaGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  // async insertAssocCatGruppo(assoc_cat_gruppo) {

  //   let formData = new FormData();

  //   for (var key in assoc_cat_gruppo) {
  //     formData.append(key, assoc_cat_gruppo[key]);
  //   }

  //   return axios.post(this.getUrlDomain("goInsertAssocCategoriaGruppoHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async insertAssocCatGruppo(assoc_cat_gruppo) {

    let formData = new FormData();

    for (var key in assoc_cat_gruppo) {
      formData.append(key, assoc_cat_gruppo[key]);
    }

    return axios.post(this.getUrlDomainGo("insertAssocCategoriaGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  // async editAssocCatGruppo(v_id) {

  //   let formData = new FormData();

  //   formData.append("id", v_id);


  //   return axios.post(this.getUrlDomain("goEditAssocCategoriaGruppoHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async editAssocCatGruppo(v_id) {

    let formData = new FormData();

    formData.append("id", v_id);


    return axios.post(this.getUrlDomainGo("editAssocCategoriaGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  // async updateAssocCatGruppo(assoc_cat_gruppo, v_id) {

  //   let formData = new FormData();

  //   formData.append("v_id", v_id);

  //   for (var key in assoc_cat_gruppo) {
  //     formData.append(key, assoc_cat_gruppo[key]);
  //   }

  //   return axios.post(this.getUrlDomain("goUpdateAssocCategoriaGruppoHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }


  async updateAssocCatGruppo(assoc_cat_gruppo, v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in assoc_cat_gruppo) {
      formData.append(key, assoc_cat_gruppo[key]);
    }

    return axios.post(this.getUrlDomainGo("updateAssocCategoriaGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  // async deleteAssocCatGruppo(v_id) {

  //   let formData = new FormData();

  //   formData.append("v_id", v_id);

  //   return axios.post(this.getUrlDomain("goDeleteAssocCategoriaGruppoHdTool"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async deleteAssocCatGruppo(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomainGo("deleteAssocCategoriaGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APICategorie({
  url: "http://emtool.local/public/api/smartbook/"
})
