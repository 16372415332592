import axios from 'axios'


class APIConfInvioMail {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  // async setConfInvioMail(issues_enable_mail)
  // {


  //   let formData = new FormData();

  //   for ( var key in issues_enable_mail ) {
  //      formData.append(key, issues_enable_mail[key]);
  //   }

  //   return axios.post(this.getUrlDomain("saveValuesApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  async setConfInvioMail(issues_enable_mail)
  {


    let formData = new FormData();

    for ( var key in issues_enable_mail ) {
       formData.append(key, issues_enable_mail[key]);
    }

    return axios.post(this.getUrlDomain("goSaveValuesConfMail") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  // async getConfInvioMail()
  // {

  //   return axios.get(this.getUrlDomain("getValuesApi") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }
  

  async getConfInvioMail()
  {

    return axios.get(this.getUrlDomain("goGetValuesConfMail") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIConfInvioMail({
  url: "http://emtool.local/public/api/alertduedate/"
})
